import { createSlice } from "@reduxjs/toolkit";
import {
  AddNewDomain,
  BulkUploadDomainAction,
  DeleteDomainAction,
  GetAllManageDomain,

} from "./ManageDomainAction";

const getInitialStatus = () => ({
  isLoading: false,
  isError: false,
  isSuccess: false,
  errorMsg: null,
  errorResponse: null,
  successMsg: null,
});

const initialState = {
  getManageDomains: getInitialStatus(),
  newDomain: getInitialStatus(),
  BulkDomain: getInitialStatus(),
  UpdateDomain: getInitialStatus(),
  DeleteDomain: getInitialStatus(),
  DomainsData: [],
  totalCount: 0,
};

const ManageDomainSlice = createSlice({
  name: "ManageDomains",
  initialState,
  reducers: {
    resetDomain: (state) => {
      state.getManageDomains = getInitialStatus();
      state.BulkDomain = getInitialStatus();
      state.newDomain = getInitialStatus();
      state.DeleteDomain = getInitialStatus();
    },
    resetDomainsValue: (state) => {
      state.getManageDomains = getInitialStatus();
      state.newDomain = getInitialStatus();
      state.BulkDomain = getInitialStatus();
      state.DeleteDomain = getInitialStatus();
      state.totalCount = 0;
      state.DomainsData = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(GetAllManageDomain.pending, (state) => {
      state.getManageDomains = getInitialStatus();
      state.getManageDomains.isLoading = true;
    });
    builder.addCase(GetAllManageDomain.rejected, (state, action) => {
      state.getManageDomains = getInitialStatus();
      state.getManageDomains.isError = true;
      state.getManageDomains.errorResponse = action.payload;
    });
    builder.addCase(GetAllManageDomain.fulfilled, (state, action) => {
      state.getManageDomains = getInitialStatus();
      state.getManageDomains.isSuccess = true;
      const { total_count, response, message } = action.payload || {};
      state.getManageDomains.successMsg = message;
      state.DomainsData = response || [];
      state.totalCount = total_count[0].count || 0;
    });

    // add new Domain
    builder.addCase(AddNewDomain.pending, (state) => {
      state.newDomain = getInitialStatus();
      state.newDomain.isLoading = true;
    });
    builder.addCase(AddNewDomain.rejected, (state, action) => {
      state.newDomain = getInitialStatus();
      state.newDomain.isError = true;
      state.newDomain.errorResponse = action.payload;
    });
    builder.addCase(AddNewDomain.fulfilled, (state, action) => {
      state.newDomain = getInitialStatus();
      state.newDomain.isSuccess = true;
      const { response, message } = action.payload || {};
      state.newDomain.successMsg = message;
      state.DomainsData = [...state.DomainsData, response];
    });
    // bulk

    builder.addCase(BulkUploadDomainAction.pending, (state) => {
      state.BulkDomain = getInitialStatus();
      state.BulkDomain.isLoading = true;
    });
    builder.addCase(BulkUploadDomainAction.rejected, (state, action) => {
      state.BulkDomain = getInitialStatus();
      state.BulkDomain.isError = true;
      state.BulkDomain.errorResponse = action.payload;
    });
    builder.addCase(BulkUploadDomainAction.fulfilled, (state, action) => {
      state.BulkDomain = getInitialStatus();
      state.BulkDomain.isSuccess = true;
      const { response, message } = action.payload || {};
      state.BulkDomain.successMsg = message;
      state.BulkDomain.errorResponse = response;
    });

    // Delete Domain
    builder.addCase(DeleteDomainAction.pending, (state) => {
      state.DeleteDomain = getInitialStatus();
      state.DeleteDomain.isLoading = true;
    });
    builder.addCase(DeleteDomainAction.rejected, (state, action) => {
      state.DeleteDomain = getInitialStatus();
      state.DeleteDomain.isError = true;
      state.DeleteDomain.errorResponse = action.payload;
    });
    builder.addCase(DeleteDomainAction.fulfilled, (state, action) => {
      state.DeleteDomain = getInitialStatus();
      state.DeleteDomain.isSuccess = true;
      const { response, message } = action.payload || {};
      state.DeleteDomain.successMsg = message;
      state.DeleteDomain.errorResponse = response;
    });
  },
});

export const { resetDomain, resetDomainsValue } = ManageDomainSlice.actions;

export default ManageDomainSlice.reducer;
