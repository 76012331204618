import { createSlice } from "@reduxjs/toolkit";
import {
  GetLiveReportFiltersAction,
  GetCustomLiveReportAction,
  GetHourlyLiveReportAction,
  ExportLiveReportAction,
} from "./ReportAction";
import { format } from "date-fns";
import { calculateDates } from "@/app/_utils/others/Helpers";

const getInitialStatus = () => ({
  isLoading: false,
  isError: false,
  isSuccess: false,
  errorMsg: null,
  errorResponse: null,
  successMsg: null,
});

const getCurrentDateRange = () => {
  const today = new Date();
  const formattedToday = format(today, "yyyy-MM-dd");
  return { from_date: formattedToday, to_date: formattedToday };
};

const getInitialTabState = () => ({
  duration: { date: true, month: false,hour:false },
  indicator: {
    feedProvider: true,
    ad_creatives: true,
    adset: true,
    channel_identifier: true,
    media_buyer: true,
    impression: true,
    clicks: true,
    channel_name__2: true,
  },
  calculation: {
    rpc: true,
    rpm: true,
    spend: true,
    revenue: true,
    cpc: true,
    margin: true,
    margin_percent: true,
    roas: true,
  },
  filter: {
    feed_provider: true,
    mediaBuyer: true,
    channel_name: true,
    channel_name_2: true,
    channel_name_3: true,
  },
  filterOptions: {
    feed_provider: [],
    mediaBuyer: [],
    channel_name: [],
    channel_name_2: [],
    channel_name_3: [],
  },
  selectedOptions: {
    channel_name: "",
    channel_name_2: "",
    channel_name_3: "",
    feed_provider: "",
    mediaBuyer: "",
  },
  date_range: {
    from_date: getCurrentDateRange().from_date,
    to_date: getCurrentDateRange().to_date,
    selectedOption: "Today",
  },
  sortby: { order: "", sorting: "" },
});

const formatOpChennal3 = (options) =>
  options.map((option) => ({
    value: option.identifier_name,
    label: option.identifier_name,
  }));

const formatOpFeed = (options) =>
  options.map((option) => ({
    value: option.value,
    label: option.label,
  }));

const formatOpMedibuyer = (options) =>
  options.map((option) => ({
    value: option.user_id,
    label: option.name,
  }));

const initialState = {
  Getfillterdata: getInitialStatus(),
  GetCustomLivedata: getInitialStatus(),
  GetHourlyLivedata: getInitialStatus(),
  GetExportdata: getInitialStatus(),
  LiveFeedData: {},
  LiveStatsData: {},
  HourlyReportData: {},
  ExportAllData: {},
  totalRecords: 0,
  HourlytotalRecords: 0,

  filters: {
    1: getInitialTabState(),
    2: getInitialTabState(),
    3: getInitialTabState(),
    4: getInitialTabState(),
    5: getInitialTabState(),
  },
  activeTab: 1,
  tabNames: {
    1: "Custom Report 1",
    2: "Custom Report 2",
    3: "Custom Report 3",
    4: "Custom Report 4",
    5: "Custom Report 5",
  },
};

const ReportSlice = createSlice({
  name: "GetReport",
  initialState,

  reducers: {
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    setFilter: (state, action) => {
      const { tabId, filterKey, filterValue } = action.payload;
      if (state.filters[tabId]) {
        state.filters[tabId][filterKey] = filterValue;
      }
    },
    setFilterOption: (state, action) => {
      const { tabId, filterKey, filterOptions } = action.payload;
      if (state.filters[tabId]) {
        state.filters[tabId].filterOptions[filterKey] = filterOptions;
      }
    },
    setSelectedOptions: (state, action) => {
      const { tabId, selectedOptions } = action.payload;
      if (state.filters[tabId]) {
        state.filters[tabId].selectedOptions = selectedOptions;
      }
    },
    setFiltersForActiveTab: (state, action) => {
      state.filters[state.activeTab] = action.payload;
    },
    setFilterData: (state, action) => {
      const { filters } = action.payload;
      state.filters = filters;
    },
    renameTab: (state, action) => {
      const { tabId, newName } = action.payload;
      if (state.tabNames[tabId]) {
        state.tabNames[tabId] = newName;
      }
    },
    setTabNames: (state, action) => {
      state.tabNames = action.payload;
    },
    setSort: (state, action) => {
      const { tabId, sortField, sortOrder } = action.payload;
      if (state.filters[tabId]) {
        state.filters[tabId].sortby = { sorting: sortField, order: sortOrder };
      }
    }
  },

  extraReducers: (builder) => {
    builder.addCase(GetLiveReportFiltersAction.pending, (state) => {
      state.Getfillterdata.isLoading = true;
    });
    builder.addCase(GetLiveReportFiltersAction.fulfilled, (state, action) => {
      state.Getfillterdata.isLoading = false;
      state.Getfillterdata.isSuccess = true;

      const { response } = action.payload;
      const updatedFilters = {};
      const tabIds = [1, 2, 3, 4, 5];

      tabIds.forEach((tabId) => {
        const tabData = response.data?.[0]?.[`custom_report_${tabId}`] || {};
        const dateRangeData = tabData.date_range || {
          selectedOption: "Today",
          from_date: "",
          to_date: "",
        };

        console.log('tabData',tabData.sortby);
        

        // const dateRange = calculateDates(dateRangeData.selectedOption || 'Today');
        // console.log(`Tab ${tabId} dateRange:`, dateRange);

        let dateRange;
        // console.log("dateRangeData", dateRangeData.selectedOption);

        if (dateRangeData.selectedOption === "Custom Range") {
          // console.log(
          //   "dateRangeData.selectedOption === ",
          //   dateRangeData.selectedOption === "Custom Range"
          // );
          dateRange = {
            from_date: dateRangeData.from_date || "",
            to_date: dateRangeData.to_date || "",
            selectedOption: "Custom Range",
          };
        } else {
          dateRange = calculateDates(dateRangeData.selectedOption || "Today");
        }

        updatedFilters[tabId] = {
          duration:
            response.data?.[0]?.[`custom_report_${tabId}`]?.duration ||
            getInitialTabState().duration,
          indicator:
            response.data?.[0]?.[`custom_report_${tabId}`]?.indicator ||
            getInitialTabState().indicator,
          calculation:
            response.data?.[0]?.[`custom_report_${tabId}`]?.calculation ||
            getInitialTabState().calculation,
          filter:
            response.data?.[0]?.[`custom_report_${tabId}`]?.filter ||
            getInitialTabState().filter,
          filterOptions: {
            feed_provider: formatOpFeed(response.feed_provider) || [],
            mediaBuyer: formatOpMedibuyer(response.media_buyer || []),
            channel_name: response.uniqueChannelNames || [],
            channel_name_2: response.uniqueChannelNames2 || [],
            channel_name_3:
              formatOpChennal3(response.uniqueChannelNames3) || [],
          },
          selectedOptions:
            tabData.selected_options || getInitialTabState().selectedOptions,
          date_range: dateRange,
          sortby: tabData.sortby || getInitialTabState().sortby,
        };
      });

      state.filters = updatedFilters;
      state.tabNames = tabIds.reduce((acc, tabId) => {
        acc[tabId] =
          response.data?.[0]?.[`custom_report_${tabId}`]?.report_name ||
          `Custom Report ${tabId}`;
        return acc;
      }, {});
    });

    builder.addCase(GetLiveReportFiltersAction.rejected, (state, action) => {
      state.Getfillterdata.isLoading = false;
      state.Getfillterdata.isError = true;
      state.Getfillterdata.errorMsg = action.payload?.response;
      state.Getfillterdata.errorResponse = action.payload;
    });

    builder.addCase(GetCustomLiveReportAction.pending, (state) => {
      state.GetCustomLivedata.isLoading = true;
    });
    builder.addCase(GetCustomLiveReportAction.fulfilled, (state, action) => {
      state.GetCustomLivedata.isLoading = false;
      state.GetCustomLivedata.isSuccess = true;
      state.GetCustomLivedata = action.payload;
      state.LiveFeedData = action.payload?.finalData;
      state.LiveStatsData = action.payload?.sumTotal;
      state.totalRecords = action.payload?.total_length;
    });
    builder.addCase(GetCustomLiveReportAction.rejected, (state, action) => {
      state.GetCustomLivedata.isLoading = false;
      state.GetCustomLivedata.isError = true;
      state.GetCustomLivedata.errorMsg = action.payload?.response;
      state.GetCustomLivedata.errorResponse = action.payload;
    });

    builder.addCase(GetHourlyLiveReportAction.pending, (state) => {
      state.GetHourlyLivedata.isLoading = true;
    });
    builder.addCase(GetHourlyLiveReportAction.fulfilled, (state, action) => {
      state.GetHourlyLivedata.isLoading = false;
      state.GetHourlyLivedata.isSuccess = true;
      state.GetHourlyLivedata = action.payload;
      state.HourlyReportData = action.payload?.finalData;
      state.HourlytotalRecords = action.payload?.total_length;
    });
    builder.addCase(GetHourlyLiveReportAction.rejected, (state, action) => {
      state.GetHourlyLivedata.isLoading = false;
      state.GetHourlyLivedata.isError = true;
      state.GetHourlyLivedata.errorMsg = action.payload?.response;
      state.GetHourlyLivedata.errorResponse = action.payload;
    });

    builder.addCase(ExportLiveReportAction.pending, (state) => {
      state.GetExportdata.isLoading = true;
    });
    builder.addCase(ExportLiveReportAction.fulfilled, (state, action) => {
      state.GetExportdata.isLoading = false;
      state.GetExportdata.isSuccess = true;
      state.ExportAllData = action.payload;
    });
    builder.addCase(ExportLiveReportAction.rejected, (state, action) => {
      state.GetExportdata.isLoading = false;
      state.GetExportdata.isError = true;
      state.GetExportdata.errorMsg = action.payload?.response;
      state.GetExportdata.errorResponse = action.payload;
    });
  },
});

export const {
  setActiveTab,
  setFilter,
  setFilterOption,
  setSelectedOptions,
  setFiltersForActiveTab,
  setFilterData,
  renameTab,
  setTabNames,
  setDateRange,
  setSort,
} = ReportSlice.actions;

export default ReportSlice.reducer;
