import { axiosInstance } from "@/app/_utils/others/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

export const GetAllManageDomain = createAsyncThunk(
  "v1/ms/get-all-domain",
  async (req, thunkAPI) => {
    try {
      const response = await axiosInstance.post("/v1/ms/get-all-domain", req, {
        headers: {
          Authorization: Cookies.get("token")
            ? `${JSON.parse(Cookies.get("token"))}`
            : "",
        },
      });

      if (response.status >= 200 && response.status < 300) {
        const data = response?.data;
        return data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const AddNewDomain = createAsyncThunk(
  "/v1/ms/upsert-domain",
  async (formdata, thunkAPI) => {
    try {
      const response = await axiosInstance.post(
        "/v1/ms/upsert-domain",
        formdata,
        {
          headers: {
            Authorization: Cookies.get("token")
              ? `${JSON.parse(Cookies.get("token"))}`
              : "",
          },
        }
      );
      if (response.status >= 200 && response.status < 300) {
        const data = response?.data;
        return data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data || error.message);
    }
  }
);

export const UpdateDomainAction = createAsyncThunk(
  "v1/UpdateDomain",
  async (req, thunkAPI) => {
    try {
      const response = await axiosInstance.post("/v1/ms/upsert-domain", req, {
        headers: {
          Authorization: Cookies.get("token")
            ? `${JSON.parse(Cookies.get("token"))}`
            : "",
        },
      });
      if (response.status >= 200 && response.status < 300) {
        const data = response?.data;
        return data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data || error.message);
    }
  }
);

export const DeleteDomainAction = createAsyncThunk(
  "/v1/ms/delete-domain",
  async (req, thunkAPI) => {
    try {
      const response = await axiosInstance.post("/v1/ms/delete-domain", req, {
        headers: {
          Authorization: Cookies.get("token")
            ? `${JSON.parse(Cookies.get("token"))}`
            : "",
        },
      });
      if (response.status >= 200 && response.status < 300) {
        const data = response?.data;
        return data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data || error.message);
    }
  }
);


export const BulkUploadDomainAction = createAsyncThunk(
  "/v1/ms/bulk-upload-brand",
  async (req, thunkAPI) => {
    try {
      const response = await axiosInstance.post("/bulk-upload-brand", req,
        {
          headers: {
            Authorization: Cookies.get("token")
              ? `${JSON.parse(Cookies.get("token"))}`
              : "",
              "Content-Type": "multipart/form-data",
          },
        
        }
    
    );
      if (response.status >= 200 && response.status < 300) {
        const data = response?.data;
        return data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data || error.message);
    }
  }
);
