//   v1/US-finance-report

import { axiosInstance } from "@/app/_utils/others/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

export const GetUSFinanceReportFiltersAction = createAsyncThunk(
  "v1/get-us-finance-custom-report",
  async (req, thunkAPI) => {
    try {
      const response = await axiosInstance.post('v1/get-us-finance-custom-report',req,
        {
          headers: {
            Authorization: Cookies.get("token")
              ? `${JSON.parse(Cookies.get("token"))}`
              : "",
          },
        }
      );
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response);
    }
  }
);



export const UpdateUsFinanceCustomReportAction = createAsyncThunk(
  "v1/us-finance-custom-report",
  async (req, thunkAPI) => {
    try {
      const response = await axiosInstance.post('v1/us-finance-custom-report', req, {
        headers: {
          Authorization: Cookies.get("token")
            ? `${JSON.parse(Cookies.get("token"))}`
            : "",
        },
      });
      
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
      throw new Error('Failed to update custom report');
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data || error.message);
    }
  }
);


export const GetCustomUsFinanceReportAction = createAsyncThunk(
  "v1/us-finance-report-data",
  async (req, thunkAPI) => {
    try {
      const response = await axiosInstance.post('v1/us-finance-report-data', req, {
        headers: {
          Authorization: Cookies.get("token")
            ? `${JSON.parse(Cookies.get("token"))}`
            : "",
        },
      });
      
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
      throw new Error('Failed to generate report');
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data || error.message);
    }
  }
);

export const GetHourlyUsFinanceReportAction = createAsyncThunk(
  "v1/us-finance-hourly-data-live-feed",
  async (req, thunkAPI) => {
    try {
      const response = await axiosInstance.post('v1/us-finance-hourly-data-live-feed',req,
        {
          headers: {
            Authorization: Cookies.get("token")
              ? `${JSON.parse(Cookies.get("token"))}`
              : "",
          },
        }
      );
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response);
    }
  }
);

export const ExportUsFinanceReportAction = createAsyncThunk(
  "/us-finance-request-data-excel",
  async (req, thunkAPI) => {
    try {
      const response = await axiosInstance.post('v1/us-finance-request-data-excel',req,
        {
          headers: {
            Authorization: Cookies.get("token")
              ? `${JSON.parse(Cookies.get("token"))}`
              : "",
          },
        }
      );
      if (response.status >= 200 && response.status < 300) {
        return response.data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response);
    }
  }
);