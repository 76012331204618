import { createSlice } from "@reduxjs/toolkit";

import {
  GetUSAccoutwiseReportAction,
  GetUSCamapignCountReportAction,
  GetUSCPATrendsReportAction,
  GetUSCpCTrendsReportAction,
  GetUSCpmTrendsReportAction,
  GetUSDashboardDetailsAction,
  GetUSImpressionTrendsReportAction,
  GetUSRpCTrendsReportAction,
  GetUSVolumeReportDetailsAction,
  GetUSWinnerLooserReportAction,
} from "./USDashboardAction";

const getInitialStatus = () => ({
  isLoading: false,
  isError: false,
  isSuccess: false,
  errorMsg: null,
  errorResponse: null,
  successMsg: null,
});

const initialState = {
  USDashboardDeatails: getInitialStatus(),
  USVolumeReportDetails: getInitialStatus(),
  USIMpressionTrendDetails: getInitialStatus(),
  CPATrendDetails: getInitialStatus(),
  GetwinLoseData: getInitialStatus(),
  CPCTrendDetails: getInitialStatus(),
  CPMTrendDetails: getInitialStatus(),
  USCamapignCount: getInitialStatus(),
  USAcountwise: getInitialStatus(),
  CPCData:{},
  CPMdata: {},
  IMpressiondata:{},
  Dashdata: {},
  Tabledata: {},
  WinnerData: {},
  LooserData: {},
  CamapignCountData:{},
  AcoutwiseData:{},
};
// GetUSDashboardDetailsAction

const USDashboardSlice = createSlice({
  name: "GetUSDashboard",
  initialState,
  reducers: {
    USDashboardResetvalue: (state) => {
      state.USDashboardDeatails = getInitialStatus();
      state.USVolumeReportDetails = getInitialStatus();
      state.USIMpressionTrendDetails = getInitialStatus();
      state.CPATrendDetails = getInitialStatus();
      state.USCamapignCount = getInitialStatus();
    },
    USDashboardResetvalueData: (state) => {
      state.USDashboardDeatails = getInitialStatus();
      state.USVolumeReportDetails = getInitialStatus();
      state.USIMpressionTrendDetails = getInitialStatus();
      state.CPATrendDetails = getInitialStatus();
      state.USCamapignCount = getInitialStatus();
    },
  },
  extraReducers: (builder) => {
    builder.addCase(GetUSDashboardDetailsAction.pending, (state) => {
      state.USDashboardDeatails.isLoading = true;
    });
    builder.addCase(GetUSDashboardDetailsAction.fulfilled, (state, action) => {
      state.USDashboardDeatails.isLoading = false;
      state.USDashboardDeatails.isSuccess = true;
      state.Tabledata = action.payload;
    });
    builder.addCase(GetUSDashboardDetailsAction.rejected, (state, action) => {
      state.USDashboardDeatails.isLoading = false;
      state.USDashboardDeatails.isError = true;
      state.USDashboardDeatails.errorMsg = action.payload?.response;
      state.USDashboardDeatails.errorResponse = action.payload;
    });
    // volume report
    builder.addCase(GetUSVolumeReportDetailsAction.pending, (state) => {
      state.USVolumeReportDetails.isLoading = true;
    });
    builder.addCase(
      GetUSVolumeReportDetailsAction.fulfilled,
      (state, action) => {
        state.USVolumeReportDetails.isLoading = false;
        state.USVolumeReportDetails.isSuccess = true;
        state.Dashdata = action.payload;
      }
    );
    builder.addCase(
      GetUSVolumeReportDetailsAction.rejected,
      (state, action) => {
        state.USVolumeReportDetails.isLoading = false;
        state.USVolumeReportDetails.isError = true;
        state.USVolumeReportDetails.errorMsg = action.payload?.response;
        state.USVolumeReportDetails.errorResponse = action.payload;
      }
    );
    // impression trends
    builder.addCase(GetUSImpressionTrendsReportAction.pending, (state) => {
      state.USIMpressionTrendDetails.isLoading = true;
    });
    builder.addCase(
      GetUSImpressionTrendsReportAction.fulfilled,
      (state, action) => {
        state.USIMpressionTrendDetails.isLoading = false;
        state.USIMpressionTrendDetails.isSuccess = true;
        state.IMpressiondata = action.payload;
      }
    );
    builder.addCase(
      GetUSImpressionTrendsReportAction.rejected,
      (state, action) => {
        state.USIMpressionTrendDetails.isLoading = false;
        state.USIMpressionTrendDetails.isError = true;
        state.USIMpressionTrendDetails.errorMsg = action.payload?.response;
        state.USIMpressionTrendDetails.errorResponse = action.payload;
      }
    );
    // rpm-trends
    builder.addCase(GetUSCPATrendsReportAction.pending, (state) => {
      state.CPATrendDetails.isLoading = true;
    });
    builder.addCase(GetUSCPATrendsReportAction.fulfilled, (state, action) => {
      state.CPATrendDetails.isLoading = false;
      state.CPATrendDetails.isSuccess = true;
      state.Dashdata = action.payload;
    });
    builder.addCase(GetUSCPATrendsReportAction.rejected, (state, action) => {
      state.CPATrendDetails.isLoading = false;
      state.CPATrendDetails.isError = true;
      state.CPATrendDetails.errorMsg = action.payload?.response;
      state.CPATrendDetails.errorResponse = action.payload;
    });

    // Cpm-trends
    builder.addCase(GetUSCpmTrendsReportAction.pending, (state) => {
      state.CPMTrendDetails.isLoading = true;
    });
    builder.addCase(GetUSCpmTrendsReportAction.fulfilled, (state, action) => {
      state.CPMTrendDetails.isLoading = false;
      state.CPMTrendDetails.isSuccess = true;
      state.CPMdata = action.payload;
    });
    builder.addCase(GetUSCpmTrendsReportAction.rejected, (state, action) => {
      state.CPMTrendDetails.isLoading = false;
      state.CPMTrendDetails.isError = true;
      state.CPMTrendDetails.errorMsg = action.payload?.response;
      state.CPMTrendDetails.errorResponse = action.payload;
    });

    // Cpc-trends
    builder.addCase(GetUSCpCTrendsReportAction.pending, (state) => {
      state.CPCTrendDetails.isLoading = true;
    });
    builder.addCase(GetUSCpCTrendsReportAction.fulfilled, (state, action) => {
      state.CPCTrendDetails.isLoading = false;
      state.CPCTrendDetails.isSuccess = true;
      state.CPCData = action.payload;
    });
    builder.addCase(GetUSCpCTrendsReportAction.rejected, (state, action) => {
      state.CPCTrendDetails.isLoading = false;
      state.CPCTrendDetails.isError = true;
      state.CPCTrendDetails.errorMsg = action.payload?.response;
      state.CPCTrendDetails.errorResponse = action.payload;
    });
    // campaign count
    builder.addCase(GetUSCamapignCountReportAction.pending, (state) => {
      state.USCamapignCount.isLoading = true;
    });
    builder.addCase(GetUSCamapignCountReportAction.fulfilled, (state, action) => {
      state.USCamapignCount.isLoading = false;
      state.USCamapignCount.isSuccess = true;
    state.CamapignCountData = action.payload;
    });
    builder.addCase(GetUSCamapignCountReportAction.rejected, (state, action) => {
      state.USCamapignCount.isLoading = false;
      state.USCamapignCount.isError = true;
      state.USCamapignCount.errorMsg = action.payload?.response;
      state.USCamapignCount.errorResponse = action.payload;
    });

       // Account count
       builder.addCase(GetUSAccoutwiseReportAction.pending, (state) => {
      state.USAcountwise.isLoading = true;
    });
    builder.addCase(GetUSAccoutwiseReportAction.fulfilled, (state, action) => {
      state.USAcountwise.isLoading = false;
      state.USAcountwise.isSuccess = true;
    state.AcoutwiseData = action.payload;
    });
    builder.addCase(GetUSAccoutwiseReportAction.rejected, (state, action) => {
      state.USAcountwise.isLoading = false;
      state.USAcountwise.isError = true;
      state.USAcountwise.errorMsg = action.payload?.response;
      state.USAcountwise.errorResponse = action.payload;
    });


    // WinnerLooserData
    builder.addCase(GetUSWinnerLooserReportAction.pending, (state) => {
      state.GetwinLoseData.isLoading = true;
    });
    builder.addCase(
      GetUSWinnerLooserReportAction.fulfilled,
      (state, action) => {
        state.GetwinLoseData.isLoading = false;
        state.GetwinLoseData.isSuccess = true;
        state.WinnerData = action.payload?.winner;
        state.LooserData = action.payload?.loosers;
        // console.log(action.payload);
      }
    );
    builder.addCase(GetUSWinnerLooserReportAction.rejected, (state, action) => {
      state.GetwinLoseData.isLoading = false;
      state.GetwinLoseData.isError = true;
      state.GetwinLoseData.errorMsg = action.payload?.response;
      state.GetwinLoseData.errorResponse = action.payload;
    });
  },
});

export const { USDashboardResetvalue, USDashboardResetvalueData } =
  USDashboardSlice.actions;
export default USDashboardSlice.reducer;
