import { axiosInstance } from "@/app/_utils/others/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

export const GetUSDashboardDetailsAction = createAsyncThunk(
  "v1/us-feedprovider-overview",
  async ({user_id,from_date, to_date }, thunkAPI) => {
    try {
      const response = await axiosInstance.post(
        "v1/us-feedprovider-overview",
        {user_id,from_date, to_date },
        {
          headers: {
            Authorization: Cookies.get("token")
              ? `${JSON.parse(Cookies.get("token"))}`
              : "",
          },

        }
      );
      if (response.status >= 200 && response.status < 300) {
        const data = response?.data;
        return data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data || error.message);
    }
  }
);


// v1/volume-report
export const GetUSVolumeReportDetailsAction = createAsyncThunk(
  "v1/us-finance-volume-report",
  async ({user_id,from_date, to_date, }, thunkAPI) => {
    try {
      const response = await axiosInstance.post(
        "v1/us-finance-volume-report",
        {user_id,from_date, to_date },
        {
          headers: {
            Authorization: Cookies.get("token")
              ? `${JSON.parse(Cookies.get("token"))}`
              : "",
          },

        }
      );
      if (response.status >= 200 && response.status < 300) {
        const data = response?.data;
        return data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data || error.message);
    }
  }
);

// v1/impression-trends

export const GetUSImpressionTrendsReportAction = createAsyncThunk(
  "v1/us-finance-impression-trends",
  async ({user_id,from_date, to_date,provider }, thunkAPI) => {
    try {
      const response = await axiosInstance.post(
        "v1/us-finance-impression-trends",
        {user_id,from_date, to_date,provider },
        {
          headers: {
            Authorization: Cookies.get("token")
              ? `${JSON.parse(Cookies.get("token"))}`
              : "",
          },

        }
      );
      if (response.status >= 200 && response.status < 300) {
        const data = response?.data;
        return data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data || error.message);
    }
  }
);


// /v1/Cpa-trends

export const GetUSCPATrendsReportAction = createAsyncThunk(
  "v1/us-finance-cpa-trends",
  async ({user_id,from_date, to_date,provider }, thunkAPI) => {
    try {
      const response = await axiosInstance.post(
        "/v1/us-finance-cpa-trends",
        {user_id,from_date, to_date,provider },
        {
          headers: {
            Authorization: Cookies.get("token")
              ? `${JSON.parse(Cookies.get("token"))}`
              : "",
          },

        }
      );
      if (response.status >= 200 && response.status < 300) {
        const data = response?.data;
        return data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data || error.message);
    }
  }
);

// CPC trends
export const GetUSCpmTrendsReportAction = createAsyncThunk(
  "v1/us-finance-cpm-trends",
  async ({user_id,from_date, to_date,provider }, thunkAPI) => {
    try {
      const response = await axiosInstance.post(
        "v1/us-finance-cpm-trends",
        {user_id,from_date, to_date,provider },
        {
          headers: {
            Authorization: Cookies.get("token")
              ? `${JSON.parse(Cookies.get("token"))}`
              : "",
          },

        }
      );
      if (response.status >= 200 && response.status < 300) {
        const data = response?.data;
        return data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data || error.message);
    }
  }
);

// Cpc trends
export const GetUSCpCTrendsReportAction = createAsyncThunk(
  "v1/us-finance-cpc-trends",
  async ({user_id,from_date, to_date,provider }, thunkAPI) => {
    try {
      const response = await axiosInstance.post(
        "/v1/us-finance-cpc-trends",
        {user_id,from_date, to_date,provider },
        {
          headers: {
            Authorization: Cookies.get("token")
              ? `${JSON.parse(Cookies.get("token"))}`
              : "",
          },

        }
      );
      if (response.status >= 200 && response.status < 300) {
        const data = response?.data;
        return data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data || error.message);
    }
  }
);

// winner looser
export const GetUSWinnerLooserReportAction = createAsyncThunk(
  "v1/us-finance-winner-looser",
  async ({user_id,from_date, to_date, }, thunkAPI) => {
    try {
      const response = await axiosInstance.post(
        "v1/us-finance-winner-looser",
        {user_id,from_date, to_date },
        {
          headers: {
            Authorization: Cookies.get("token")
              ? `${JSON.parse(Cookies.get("token"))}`
              : "",
          },

        }
      );
      if (response.status >= 200 && response.status < 300) {
        const data = response?.data;
        return data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data || error.message);
    }
  }
);


// USCamapignCount
export const GetUSCamapignCountReportAction = createAsyncThunk(
  "v1/us-finance-campaign-count",
  async ({user_id,from_date, to_date, }, thunkAPI) => {
    try {
      const response = await axiosInstance.post(
        "v1/us-finance-campaign-count",
        {user_id,from_date, to_date },
        {
          headers: {
            Authorization: Cookies.get("token")
              ? `${JSON.parse(Cookies.get("token"))}`
              : "",
          },

        }
      );
      if (response.status >= 200 && response.status < 300) {
        const data = response?.data;
        return data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data || error.message);
    }
  }
);


// Account wise
export const GetUSAccoutwiseReportAction = createAsyncThunk(
  "v1/us-finance-account-wise-data",
  async ({user_id,from_date, to_date, }, thunkAPI) => {
    try {
      const response = await axiosInstance.post(
        "v1/us-finance-account-wise-data",
        {user_id,from_date, to_date },
        {
          headers: {
            Authorization: Cookies.get("token")
              ? `${JSON.parse(Cookies.get("token"))}`
              : "",
          },

        }
      );
      if (response.status >= 200 && response.status < 300) {
        const data = response?.data;
        return data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data || error.message);
    }
  }
);
