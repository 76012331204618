import { createSlice } from "@reduxjs/toolkit";
import { GetAllIP, IpDeleteAction,AddNewIPAction } from "./ManageIPAction";

const getInitialStatus = () => ({
  isLoading: false,
  isError: false,
  isSuccess: false,
  errorMsg: null,
  errorResponse: null,
  successMsg: null,
});

const initialState = {
  getAllIPData: getInitialStatus(),
  deleteIPData: getInitialStatus(), 
  AddNewIPData: getInitialStatus(), 
  IPData: [],
  totalCount: 0,
};

const ManageIPSlice = createSlice({
  name: "ManageIP",
  initialState,
  reducers: {
    resetIP: (state) => {
      state.getAllIPData = getInitialStatus();
      state.deleteIPData = getInitialStatus(); 
      state.AddNewIPData = getInitialStatus(); 
    },
  },
  extraReducers: (builder) => {
    // Fetch All IP
    builder.addCase(GetAllIP.pending, (state) => {
      state.getAllIPData = getInitialStatus();
      state.getAllIPData.isLoading = true;
    });
    builder.addCase(GetAllIP.rejected, (state, action) => {
      state.getAllIPData = getInitialStatus();
      state.getAllIPData.isError = true;
      const { response, message } = action.payload || {};
      state.getAllIPData.errorResponse = response?.data?.message || message || "Failed to fetch IPs"; 
      console.error('Error fetching IPs:', action.payload); 
    });
    builder.addCase(GetAllIP.fulfilled, (state, action) => {
      state.getAllIPData = getInitialStatus();
      state.getAllIPData.isSuccess = true;
      state.IPData = action.payload.data || [];
      state.totalCount = action.payload.total_count?.count || 0; 
      state.getAllIPData.successMsg = "IPs fetched successfully"; 
    });

    // Delete IP
    builder.addCase(IpDeleteAction.pending, (state) => {
      state.deleteIPData = getInitialStatus();
      state.deleteIPData.isLoading = true;
    });
    builder.addCase(IpDeleteAction.rejected, (state, action) => {
      state.deleteIPData = getInitialStatus();
      state.deleteIPData.isError = true;
      const { response, message } = action.payload || {};
      state.deleteIPData.errorMsg = response?.data?.message || message || "Failed to delete IP"; 
      console.error('Error deleting IP:', action.payload); 
    });
    builder.addCase(IpDeleteAction.fulfilled, (state, action) => {
      state.deleteIPData = getInitialStatus();
      state.deleteIPData.isSuccess = true;
      state.deleteIPData.successMsg = "IP deleted successfully"; 
    });

    // Add NewIP 
    builder.addCase(AddNewIPAction.pending, (state) => {
      state.AddNewIPData = getInitialStatus();
      state.AddNewIPData.isLoading = true;
    });
    builder.addCase(AddNewIPAction.rejected, (state, action) => {
      state.AddNewIPData = getInitialStatus();
      state.AddNewIPData.isError = true;
      const { response, message } = action.payload || {};
      state.AddNewIPData.errorMsg = response?.data?.message || message || "Failed to Add IP"; 
    });
    builder.addCase(AddNewIPAction.fulfilled, (state, action) => {
      state.AddNewIPData = getInitialStatus();
      state.AddNewIPData.isSuccess = true;
      state.AddNewIPData.successMsg = "IP Added successfully"; 
    });
  },
});

export const { resetIP } = ManageIPSlice.actions;

export default ManageIPSlice.reducer;
