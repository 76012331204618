
import { createSlice } from "@reduxjs/toolkit";
import { GetAllBrandsAction, GetAllDiscoveryAction, GetAllSavedAction, GetDiscoveryFilterAction, GetWinnerAction, PostSaveOrnotAction } from "./DiscoveryAction";

const getInitialStatus = () => ({
  isLoading: false,
  isError: false,
  isSuccess: false,
  errorMsg: null,
  errorResponse: null,
  successMsg: null,
});

const initialState = {
  getAllDiscovery: getInitialStatus(),
  getFilters: getInitialStatus(),
  getBrands: getInitialStatus(),
  Postsave: getInitialStatus(),
  GetallSaved: getInitialStatus(),
  GetWinner: getInitialStatus(),
  DiscoveryData: [],
  DsicoveryFilters:[],
  DiscoveryBrandsData:[],
  GetallSavedData:[],
  GetallWinnerData:[],
  totalAddCount:[],
  totalBrandCount: 0,
  totalSavedCount: 0,
  totalWinnerCount: 0,
    // New filter state properties
    selectedPlatforms: [],
  selectedPageCategories: [],
  selectedUrlType: "",
  dateRange: { start_date: null, end_date: null },
  searchValue: "",
};

const DiscoverySlice = createSlice({
  name: "ManageDiscovery",
  initialState,
  reducers: {
    resetDiscovery: (state) => {
      state.getAllDiscovery = getInitialStatus();
      state.getFilters = getInitialStatus();
      state.Postsave = getInitialStatus();
      state.GetallSaved = getInitialStatus();
    },
    setSelectedPlatforms: (state, action) => {
      state.selectedPlatforms = action.payload;
    },
    setSelectedPageCategories: (state, action) => {
      state.selectedPageCategories = action.payload;
    },
    setSelectedUrlType: (state, action) => {
      state.selectedUrlType = action.payload;
    },
    setDateRange: (state, action) => {
      state.dateRange = action.payload;
    },
    setSearchValue: (state, action) => {
      state.searchValue = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(GetAllDiscoveryAction.pending, (state) => {
      state.getAllDiscovery = getInitialStatus();
      state.getAllDiscovery.isLoading = true;
    });
    builder.addCase(GetAllDiscoveryAction.rejected, (state, action) => {
      state.getAllDiscovery = getInitialStatus();
      state.getAllDiscovery.isError = true;
      const { response, message } = action.payload || {};
      state.getAllDiscovery.errorResponse = message;
    });
    builder.addCase(GetAllDiscoveryAction.fulfilled, (state, action) => {
      state.getAllDiscovery = getInitialStatus();
      state.getAllDiscovery.isSuccess = true;
      state.DiscoveryData = action.payload.data || [];
      state.totalAddCount = action.payload.total || 0;
      // console.log('action.payload.total',action.payload.total);
      
    });
    // getFilters
    builder.addCase(GetDiscoveryFilterAction.pending, (state) => {
      state.getFilters = getInitialStatus();
      state.getFilters.isLoading = true;
    });
    builder.addCase(GetDiscoveryFilterAction.rejected, (state, action) => {
      state.getFilters = getInitialStatus();
      state.getFilters.isError = true;
      const { response, message } = action.payload || {};
      state.getFilters.errorResponse = message;
    });
    builder.addCase(GetDiscoveryFilterAction.fulfilled, (state, action) => {
      state.getFilters = getInitialStatus();
      state.getFilters.isSuccess = true;
      state.DsicoveryFilters = action.payload.data || [];
    });

    // getBrands
    builder.addCase(GetAllBrandsAction.pending, (state) => {
      state.getBrands = getInitialStatus();
      state.getBrands.isLoading = true;
    });
    builder.addCase(GetAllBrandsAction.rejected, (state, action) => {
      state.getBrands = getInitialStatus();
      state.getBrands.isError = true;
      const { response, message } = action.payload || {};
      state.getBrands.errorResponse = message;
    });
    builder.addCase(GetAllBrandsAction.fulfilled, (state, action) => {
      state.getBrands = getInitialStatus();
      state.getBrands.isSuccess = true;
      state.DiscoveryBrandsData = action.payload.data || [];
      state.totalBrandCount = action.payload.data?.total || 0;
    });

    // PostSaveOrnotAction
    builder.addCase(PostSaveOrnotAction.pending, (state) => {
      state.Postsave = getInitialStatus();
      state.Postsave.isLoading = true;
    });
    builder.addCase(PostSaveOrnotAction.rejected, (state, action) => {
      state.Postsave = getInitialStatus();
      state.Postsave.isError = true;
      const { response, message } = action.payload || {};
      state.Postsave.errorResponse = message;
    });
    builder.addCase(PostSaveOrnotAction.fulfilled, (state, action) => {
      state.Postsave = getInitialStatus();
      state.Postsave.isSuccess = true;
      // state.DiscoveryBrandsData = action.payload.data || [];
      // state.totalBrandCount = action.payload.data?.total || 0;
    });

      // GetallSavedAction
      builder.addCase(GetAllSavedAction.pending, (state) => {
      state.GetallSaved = getInitialStatus();
      state.GetallSaved.isLoading = true;
    });
    builder.addCase(GetAllSavedAction.rejected, (state, action) => {
      state.GetallSaved = getInitialStatus();
      state.GetallSaved.isError = true;
      const { response, message } = action.payload || {};
      state.GetallSaved.errorResponse = message;
    });
    builder.addCase(GetAllSavedAction.fulfilled, (state, action) => {
      state.GetallSaved = getInitialStatus();
      state.GetallSaved.isSuccess = true;
      state.GetallSavedData = action.payload.data || [];
      state.totalSavedCount = action.payload.data?.total || 0;
    });
    // GetWinnerAction

    builder.addCase(GetWinnerAction.pending, (state) => {
      state.GetWinner = getInitialStatus();
      state.GetWinner.isLoading = true;
    });
    builder.addCase(GetWinnerAction.rejected, (state, action) => {
      state.GetWinner = getInitialStatus();
      state.GetWinner.isError = true;
      const { response, message } = action.payload || {};
      state.GetWinner.errorResponse = message;
    });
    builder.addCase(GetWinnerAction.fulfilled, (state, action) => {
      state.GetWinner = getInitialStatus();
      state.GetWinner.isSuccess = true;
      state.GetallWinnerData = action.payload.data || [];
      console.log('total',action.payload.total );
      
      state.totalWinnerCount = action.payload.total || 0;
    });
    

    
  },
});

export const { resetDiscovery,
  setSelectedPlatforms,
  setSelectedPageCategories,
  setSelectedUrlType,
  setDateRange,
  setSearchValue,
 } = DiscoverySlice.actions;

export default DiscoverySlice.reducer;
