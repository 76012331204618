import { axiosInstance } from "@/app/_utils/others/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

export const GetAllIP = createAsyncThunk(
  "v1/ip-details",
  async (req, thunkAPI) => {
    try {
      const response = await axiosInstance.post("v1/ip-details", req, {
        headers: {
          Authorization: Cookies.get("token")
            ? `${JSON.parse(Cookies.get("token"))}` 
            : "",
        },
      });

      if (response.status >= 200 && response.status < 300) {
        const data = response?.data;
        return data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const AddNewIPAction = createAsyncThunk(
  "v1/add-ip-details",
  async (req, thunkAPI) => {
    try {
      const response = await axiosInstance.post("v1/add-ip-details", req, {
        headers: {
          Authorization: Cookies.get("token")
            ? `${JSON.parse(Cookies.get("token"))}` 
            : "",
        },
      });

      if (response.status >= 200 && response.status < 300) {
        const data = response?.data;
        return data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);



// v1/delete-ip
export const IpDeleteAction = createAsyncThunk(
  "v1/delete-ip",
  async (req, thunkAPI) => {
    try {
      const response = await axiosInstance.post("v1/delete-ip", req, {
        headers: {
          Authorization: Cookies.get("token")
            ? `${JSON.parse(Cookies.get("token"))}` 
            : "",
        },
      });

      if (response.status >= 200 && response.status < 300) {
        const data = response?.data;
        return data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);