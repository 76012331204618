import { axiosInstance } from "@/app/_utils/others/axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

export const GetAllDiscoveryAction = createAsyncThunk(
  "get-ads-library",
  async (req, thunkAPI) => {
    try {
      const response = await axiosInstance.post(
        "/get-ads-library",
        req,
        {
          headers: {
            Authorization: Cookies.get("token")
              ? `${JSON.parse(Cookies.get("token"))}`
              : "",
          },
        }
      );
      if (response.status >= 200 && response.status < 300) {
        const data = response?.data;
        return data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data || error.message);
    }
  }
);


export const GetDiscoveryFilterAction = createAsyncThunk(
  "api/get-filter-details",
  async (req, thunkAPI) => {
    try {
      const response = await axiosInstance.get(
        "/get-filter-details",
        {
          headers: {
            Authorization: Cookies.get("token")
              ? `${JSON.parse(Cookies.get("token"))}`
              : "",
          },
        }
      );
      if (response.status >= 200 && response.status < 300) {
        const data = response?.data;
        return data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data || error.message);
    }
  }
);




export const GetAllBrandsAction = createAsyncThunk(
  "get-brand-details",
  async (req, thunkAPI) => {
    try {
      const response = await axiosInstance.post(
        "/get-brand-details",
        req,
        {
          headers: {
            Authorization: Cookies.get("token")
              ? `${JSON.parse(Cookies.get("token"))}`
              : "",
          },
        }
      );
      if (response.status >= 200 && response.status < 300) {
        const data = response?.data;
        return data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data || error.message);
    }
  }
);






export const PostSaveOrnotAction = createAsyncThunk(
  "get-saved-details",
  async (req, thunkAPI) => {
    try {
      const response = await axiosInstance.post(
        "/get-saved-details",
        req,
        {
          headers: {
            Authorization: Cookies.get("token")
              ? `${JSON.parse(Cookies.get("token"))}`
              : "",
          },
        }
      );
      if (response.status >= 200 && response.status < 300) {
        const data = response?.data;
        return data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data || error.message);
    }
  }
);


export const GetAllSavedAction = createAsyncThunk(
  "get-saved-list",
  async (req, thunkAPI) => {
    try {
      const response = await axiosInstance.post(
        "/get-saved-list",
        req,
        {
          headers: {
            Authorization: Cookies.get("token")
              ? `${JSON.parse(Cookies.get("token"))}`
              : "",
          },
        }
      );
      if (response.status >= 200 && response.status < 300) {
        const data = response?.data;
        return data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data || error.message);
    }
  }
);




export const GetWinnerAction = createAsyncThunk(
  "winner-logic",
  async (req, thunkAPI) => {
    try {
      const response = await axiosInstance.post(
        "/winner-logic",
        req,
        {
          headers: {
            Authorization: Cookies.get("token")
              ? `${JSON.parse(Cookies.get("token"))}`
              : "",
          },
        }
      );
      if (response.status >= 200 && response.status < 300) {
        const data = response?.data;
        return data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data || error.message);
    }
  }
);
